var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.menuItems),function(item){return [(item.is_section && item.access.includes(_vm.$store.getters.currentUser.usertype))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(item.title))]),_c('i',{class:'menu-icon ' + item.icon})]):_vm._e(),(
            (
                !item.is_section
                &&
                !item.has_children
                &&
                item.access.includes(_vm.$store.getters.currentUser.usertype)
            )
            &&
            (
                (_vm.$store.getters.currentUser.usertype !== 'Sodalizio')
                ||
                (
                    (_vm.$store.getters.currentUser.usertype === 'Sodalizio')
                    &&
                    (
                        (
                            (typeof item.has_single !== 'undefined')
                            &&
                            !item.has_single
                            &&
                            (_vm.$store.getters.currentUser.adminsportingclub.length > 1)
                        )
                        ||
                        (
                            (typeof item.has_single !== 'undefined')
                            &&
                            item.has_single
                            &&
                            (_vm.$store.getters.currentUser.adminsportingclub.length === 1)
                        )
                        ||
                        (typeof item.has_single === 'undefined')
                    )
                )
            )
            &&
            (
                (_vm.$store.getters.currentUser.usertype !== 'Sodalizio')
                ||
                (
                    (_vm.$store.getters.currentUser.usertype === 'Sodalizio')
                    &&
                    (
                        (
                            (typeof item.has_none_sportingclubs !== 'undefined')
                            &&
                            item.has_none_sportingclubs
                            &&
                            (_vm.$store.getters.currentUser.adminsportingclub.length === 0)
                        )
                        ||
                        (
                            (typeof item.has_none_sportingclubs !== 'undefined')
                            &&
                            !item.has_none_sportingclubs
                            &&
                            (_vm.$store.getters.currentUser.adminsportingclub.length > 0)
                        )
                        ||
                        (typeof item.has_none_sportingclubs === 'undefined')
                    )
                )
            )
            &&
            (
                (_vm.$store.getters.currentUser.usertype !== 'Sodalizio')
                ||
                (
                    (_vm.$store.getters.currentUser.usertype === 'Sodalizio')
                    &&
                    (
                        (
                            (typeof item.has_some_sportingclubs !== 'undefined')
                            &&
                            item.has_some_sportingclubs
                            &&
                            (_vm.$store.getters.currentUser.adminsportingclub.length >= 1)
                        )
                        ||
                        (typeof item.has_some_sportingclubs === 'undefined')
                    )
                )
            )
        )?_c('router-link',{attrs:{"custom":"","to":((_vm.$store.getters.currentUser.usertype === 'Comitato') ? item.route.replace('[ID_COMITATO]', _vm.$store.getters.currentUser.admincommittee) : ((_vm.$store.getters.currentUser.usertype === 'Sodalizio' && typeof item.has_single !== 'undefined' && item.has_single && _vm.$store.getters.currentUser.adminsportingclub.length === 1) ? item.route.replace('[ID_SODALIZIO]', _vm.$store.getters.currentUser.adminsportingclub[0]) : item.route))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":((_vm.$store.getters.currentUser.usertype === 'Comitato') ? href.replace('[ID_COMITATO]', _vm.$store.getters.currentUser.admincommittee) : ((_vm.$store.getters.currentUser.usertype === 'Sodalizio' && typeof item.has_single !== 'undefined' && item.has_single && _vm.$store.getters.currentUser.adminsportingclub.length === 1) ? href.replace('[ID_SODALIZIO]', _vm.$store.getters.currentUser.adminsportingclub[0]) : href))},on:{"click":navigate}},[_c('i',{class:'menu-icon ' + item.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.title))])])])]}}],null,true)}):_vm._e(),(
            (!item.is_section && item.has_children && item.access.includes(_vm.$store.getters.currentUser.usertype))
            &&
            (
                (
                    _vm.$store.getters.currentUser.usertype !== 'Sodalizio'
                )
                ||
                (
                    _vm.$store.getters.currentUser.usertype === 'Sodalizio'
                )
                &&
                (
                    (
                        typeof item.has_none_sportingclubs !== 'undefined'
                        &&
                        item.has_none_sportingclubs
                        &&
                        _vm.$store.getters.currentUser.adminsportingclub.length === 0
                    )
                    ||
                    (
                        typeof item.has_none_sportingclubs !== 'undefined'
                        &&
                        !item.has_none_sportingclubs
                        &&
                        _vm.$store.getters.currentUser.adminsportingclub.length > 0
                    )
                    ||
                    (
                        typeof item.has_none_sportingclubs === 'undefined'
                    )
                )
            )
        )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren(item.route) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{class:'menu-icon ' + item.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.title))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.title))])])]),_vm._l((item.children),function(subitem){return (subitem.access.includes(_vm.$store.getters.currentUser.usertype))?_c('router-link',{key:subitem.route,attrs:{"custom":"","to":subitem.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(subitem.title))])])])]}}],null,true)}):_vm._e()})],2)])]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }