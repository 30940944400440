<template>
    <div class="mr-4">
        <div class="topbar-item">
            <div class="btn btn-sm btn-rounded d-flex align-items-center justify-content-between bg-primary text-white"
                id="kt_app_chat_launch_btn" @click="show = !show">
                <span class="symbol symbol-circle symbol-25">
                    <img src="media/chat_avatar.jpg" />
                </span>
                <span class="ml-2">Zoe - Assistente AI</span>
            </div>
        </div>

        <div class="modal modal-sticky modal-sticky-bottom-right" :class="{ 'show': show }" id="kt_chat_modal"
            role="dialog" data-backdrop="false" :style="'display: ' + (show ? 'block' : 'none') + ';'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <!--begin::Header-->
                        <div class="card-header align-items-center px-4 py-3">
                            <div class="text-center flex-grow-1">
                                <div
                                    class="text-dark-75 font-weight-bold font-size-h5 d-flex align-items-center justify-content-center">
                                    <span class="symbol symbol-circle symbol-30 border border-primary">
                                        <img src="media/chat_avatar.jpg" />
                                    </span>
                                    <span class="ml-2">Chiedi a Zoe la tua Assistente AI (beta)</span>
                                </div>
                                <div>
                                    <span class="label label-dot label-success"></span>
                                    <span class="font-weight-bold text-muted font-size-sm">Attivo</span>
                                </div>
                            </div>
                            <div class="text-right flex-grow-1">
                                <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                    @click="show = false">
                                    <i class="ki ki-close icon-1x"></i>
                                </button>
                            </div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body py-3 px-5">
                            <!--begin::Scroll-->
                            <div class="scroll scroll-pull" data-height="400" data-mobile-height="350">
                                <!--begin::Messages-->
                                <div id="messages-list" class="messages">
                                    <div v-for="message in messages" :key="message.id" :id="'message-' + message.id">
                                        <!--begin::Message Out-->
                                        <div v-if="message.question" class="d-flex flex-column mb-5 align-items-end"
                                            :id="'question-' + message.id">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <span class="text-muted font-size-sm mr-1">{{ message.datequestion
                                                        }}</span>
                                                    <a href="#"
                                                        class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Tu</a>
                                                </div>
                                            </div>
                                            <div
                                                class="mt-2 rounded p-5 bg-chat-question text-white font-weight-bold font-size-lg text-right max-w-400px">
                                                {{ message.question }}</div>
                                        </div>
                                        <!--end::Message Out-->
                                        <!--begin::Message In-->
                                        <div v-if="message.answer || (typeof message.isloading !== 'undefined' && message.isloading)"
                                            class="d-flex flex-column mb-5 align-items-start"
                                            :id="'answer-' + message.id">
                                            <div v-if="message.dateanswer || (typeof message.isloading !== 'undefined' && message.isloading)"
                                                class="d-flex align-items-center">
                                                <div>
                                                    <a href="#"
                                                        class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Zoe</a>
                                                    <span class="text-muted font-size-sm ml-1">{{ message.dateanswer
                                                        }}</span>
                                                </div>
                                            </div>
                                            <div v-if="message.answer"
                                                class="mt-2 rounded p-5 message-answer bg-chat-answer text-dark-75 font-weight-bold font-size-lg text-left max-w-400px"
                                                v-html="message.answer"></div>
                                            <div v-if="typeof message.isloading !== 'undefined' && message.isloading"
                                                class="mt-2 p-5 text-left">
                                                <img src="media/loading.gif" alt="loading" />
                                            </div>
                                        </div>
                                        <!--end::Message In-->
                                    </div>
                                </div>
                                <!--end::Messages-->
                            </div>
                            <!--end::Scroll-->
                        </div>
                        <!--end::Body-->
                        <!--begin::Footer-->
                        <div class="card-footer align-items-center py-5 px-5">
                            <!--begin::Compose-->
                            <div class="d-flex align-items-center justify-content-between mt-0">
                                <textarea class="form-control border-1 p-2 mr-1" rows="1"
                                    placeholder="Scrivi un messaggio" v-model="question"
                                    @keyup.enter.preventDefaul()="sendMessage()"></textarea>
                                <div>
                                    <button type="button"
                                        class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                                        @click.prevent="sendMessage()">Invia</button>
                                </div>
                            </div>
                            <!--begin::Compose-->
                        </div>
                        <!--end::Footer-->
                    </div>
                    <!--end::Card-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KTLayoutQuickChatPanel from "@/assets/js/layout/extended/chat.js";
import ApiService from "@/core/services/api.service";
import KTUtil from "@/assets/js/components/util.js";
import $ from "jquery";
import dayjs from 'dayjs';
import 'dayjs/locale/it'
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
    name: "KTChatPanel",

    data() {
        return {
            show: false,

            question: '',

            messages: [],
        };
    },

    async created() {
        const messages = await this.getPreviousMessages();

        this.messages = messages;

        dayjs.extend(relativeTime);
        dayjs.locale('it');
    },

    mounted() {
        KTLayoutQuickChatPanel.init();

        //$(document).ready(function () {
        //$('.scroll').data('height', $(window).height() - 400);
        //});
    },

    watch: {
        show: function (value) {
            if (value) {
                this.scrollBottom();
            }
        },

        messages: {
            handler() {
                this.scrollBottom();
            },
            deep: true,
        },
    },

    methods: {
        sendMessage() {
            let question = JSON.parse(JSON.stringify(this.question.trim()));

            let url = '/chat/ask';

            if (question === '') {
                this.$bvToast.toast('Inserisci una domanda', {
                    title: 'Errore',
                    variant: 'danger',
                    solid: true
                });

                return;
            }

            this.question = '';

            this.messages.push({
                id: this.messages[this.messages.length - 1].id + 1,
                datequestion: new dayjs(new Date()).fromNow(),
                question: question,
                dateanswer: '',
                answer: '',
                isloading: true,
            });

            ApiService.post(url, { question: question })
                .then((response) => {
                    if (response.data.status === 'KO') {
                        this.message = response.data.message;
                        this.message_type = 'danger';

                        this.$bvToast.toast(response.data.message, {
                            title: response.data.message,
                            variant: 'danger',
                            solid: true
                        });
                    }

                    if (response.data.status === 'OK') {
                        this.$set(this.messages, this.messages.length - 1, {
                            id: response.data.id,
                            datequestion: response.data.datequestion,
                            question: response.data.question,
                            dateanswer: response.data.dateanswer,
                            answer: response.data.answer,
                            isloading: false,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        async getPreviousMessages() {
            let url = '/chat/get-messages';

            let messages = [];

            await ApiService.post(url)
                .then((response) => {
                    if (response.data.status === 'KO') {
                        this.message = response.data.message;
                        this.message_type = 'danger';

                        this.$bvToast.toast(response.data.message, {
                            title: response.data.message,
                            variant: 'danger',
                            solid: true
                        });
                    }

                    if (response.data.status === 'OK') {
                        messages = JSON.parse(JSON.stringify(response.data.messages));
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            return messages;
        },

        scrollBottom() {
            setTimeout(() => {
                let element = KTUtil.getById('kt_chat_modal');

                let scrollEl = KTUtil.find(element, '.scroll');

                let messagesHeight = $('#messages-list').height();

                let lastMessageHeight = $('#message-' + this.messages[this.messages.length - 1].id).height();

                scrollEl.scrollTop = parseInt(messagesHeight) - parseInt(lastMessageHeight);

                var ps;
                if (ps = KTUtil.data(scrollEl).get('ps')) {
                    ps.update();
                }
            }, 1000);
        },
    },
}
</script>

<style>
.bg-chat-question {
    background-color: #2c81d7;
}

.bg-chat-answer {
    background-color: #f4f4f4;
}

img[alt="loading"] {
    width: 100px;
    height: 100px;
}

.message-answer ul,
.message-answer ol,
.message-answer dt {
    margin-left: 15px;
}

.message-answer ul li,
.message-answer ol li,
.message-answer dt li {
    margin-bottom: 1rem;
}

.message-answer ul li p,
.message-answer ol li p,
.message-answer dt li p {
    margin-bottom: 0px;
}
</style>
