<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="topbar-item text-decoration-none"
    no-caret
    right
    no-flip
    ref="maindropdown"
  >
    <template v-slot:button-content>
      <button class="btn btn-primary btn-sm btn-dropdown mr-1 pulse pulse-primary">
        Notifiche <b-badge pill variant="danger">{{ ((totalNotifications > 99) ? '99+' : totalNotifications) }}</b-badge>
      </button>
    </template>
    <b-dropdown-text tag="div" class="min-w-md-450px" ref="notifications-dropdown-text">
      <form>
        <div>
          <div
            class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
            :style="{ backgroundImage: `url(${backgroundImage})`, minWidth: '420px' }"
          >
            <h4 class="d-flex flex-center rounded-top">
              <button class="btn-success btn-sm" @click.prevent="goToNotifications()">Leggi Tutte le Notifiche</button>
            </h4>

            <ul
              class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab"
                  data-tab="0"
                  class="nav-link active"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  style="margin-left: 16px;"
                >
                  Affiliazioni
                  <span class="badge pill bg-danger ml-1">{{$store.getters.currentNotifications.affiliazioni.length}}</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab"
                  data-tab="1"
                  class="nav-link"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  Tesseramenti
                  <span class="badge pill bg-danger ml-1">{{$store.getters.currentNotifications.tesseramenti.length}}</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  v-on:click="setActiveTab"
                  data-tab="2"
                  class="nav-link"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  Altro
                  <span class="badge pill bg-danger ml-1">{{$store.getters.currentNotifications.altro.length}}</span>
                </a>
              </li>
            </ul>
          </div>

          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <perfect-scrollbar
                class="navi navi-hover scroll my-4"
                style="max-height: 40vh; position: relative;"
                v-if="$store.getters.currentNotifications.affiliazioni.length"
              >
                <template v-for="(item, i) in $store.getters.currentNotifications.affiliazioni">
                  <a href="#" class="navi-item" v-bind:key="i">
                    <div class="navi-link">
                      <div class="navi-icon mr-2">
                        <i v-bind:class="item.icon"></i>
                      </div>
                      <div class="navi-text">
                        <div class="font-weight-bold">
                          {{ item.title }}
                        </div>
                        <div class="text-muted">
                          {{ item.desc }}
                        </div>
                      </div>
                    </div>
                  </a>
                </template>
              </perfect-scrollbar>
              <div v-else class="d-flex flex-center text-center text-muted min-h-200px">
                Nessuna nuova notifica
              </div>
            </b-tab>

            <b-tab>
              <perfect-scrollbar
                class="navi navi-hover scroll my-4"
                style="max-height: 40vh; position: relative;"
                v-if="$store.getters.currentNotifications.tesseramenti.length"
              >
                <template v-for="(item, i) in $store.getters.currentNotifications.tesseramenti">
                  <a href="#" class="navi-item" v-bind:key="i">
                    <div class="navi-link">
                      <div class="navi-icon mr-2">
                        <i v-bind:class="item.icon"></i>
                      </div>
                      <div class="navi-text">
                        <div class="font-weight-bold">
                          {{ item.title }}
                        </div>
                        <div class="text-muted">
                          {{ item.desc }}
                        </div>
                      </div>
                    </div>
                  </a>
                </template>
              </perfect-scrollbar>
              <div v-else class="d-flex flex-center text-center text-muted min-h-200px">
                Nessuna nuova notifica
              </div>
            </b-tab>

            <b-tab>
              <perfect-scrollbar
                class="navi navi-hover scroll my-4"
                style="max-height: 40vh; position: relative;"
                v-if="$store.getters.currentNotifications.altro.length"
              >
                <template v-for="(item, i) in $store.getters.currentNotifications.altro">
                  <a href="#" class="navi-item" v-bind:key="i">
                    <div class="navi-link">
                      <div class="navi-icon mr-2">
                        <i v-bind:class="item.icon"></i>
                      </div>
                      <div class="navi-text">
                        <div class="font-weight-bold">
                          {{ item.title }}
                        </div>
                        <div class="text-muted">
                          {{ item.desc }}
                        </div>
                      </div>
                    </div>
                  </a>
                </template>
              </perfect-scrollbar>
              <div v-else class="d-flex flex-center text-center text-muted min-h-200px">
                Nessuna nuova notifica
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </form>
    </b-dropdown-text>
  </b-dropdown>
</template>

<style lang="scss">
 /* hide default vue-bootstrap tab links */
 .hide-tabs > div:not(.tab-content) {
   display: none;
 }
</style>

<script>
//import ApiService from "@/core/services/api.service";

 export default {
   name: "KTDropdownNotification",
   data() {
     return {
       tabIndex: 0,

       loading: false,
     };
   },

   created() {
     //if (this.$store.getters.currentUser.id) {
     if (false) {
       let vm = this;
       setInterval(function() {
         vm.loadNotifications();
       }, 240000);
     };
   },

   methods: {
     setActiveTab(event) {
       const tab = event.target.closest('[role="tablist"]');
       const links = tab.querySelectorAll(".nav-link");
       // remove active tab links
       for (let i = 0; i < links.length; i++) {
         links[i].classList.remove("active");
       }

       // set clicked tab index to bootstrap tab
       this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

       // set current active tab
       event.target.classList.add("active");
     },

     goToNotifications() {
       this.$refs.maindropdown.hide();

       this.$router.push("/notifications").catch(()=>{});
     },

     loadNotifications() {
       if (this.$store.getters.currentUser.iduser) {
         if (typeof this.$store.getters.currentUser.usertype !== "undefined") {
           let url = '';

           url = '/notifications/load-unread-notifications?iduser=' + this.$store.getters.currentUser.iduser;
           ApiService.query(url)
                     .then((response) => {
                       this.notifications = JSON.parse(JSON.stringify(response.data.notifications));
                     })
                     .catch((error) => {
                     });
         }
       }
     },
   },

   computed: {
     backgroundImage() {
       return process.env.BASE_URL + "media/misc/bg-1.jpg";
     },

     totalNotifications() {

      if (typeof this.$store.getters.currentNotifications === "undefined") {
        return 0;
      }

      if (typeof this.$store.getters.currentNotifications.affiliazioni === "undefined") {
        return 0;
      }

      if (typeof this.$store.getters.currentNotifications.tesseramenti === "undefined") {
        return 0;
      }

      if (typeof this.$store.getters.currentNotifications.altro === "undefined") {
        return 0;
      }

      return this.$store.getters.currentNotifications.affiliazioni.length + this.$store.getters.currentNotifications.tesseramenti.length + this.$store.getters.currentNotifications.altro.length;
     },
   }
 };
</script>
