<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
            {{year}} &nbsp;&copy;&nbsp;
        </span>
        <a
            href="https://www.acsi.it"
            target="_blank"
            class="text-dark-75 text-hover-primary"
        >ACSI</a>
        <span class="text-muted font-weight-bold"> - Powered BY</span>
        <a
            href="https://www.bytewise.it"
            target="_blank"
            class="text-dark-75 text-hover-primary"
        >
            Bytewise
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
     data() {
         return {
             year: '',
         }
     },
     created() {
         this.year = '2020';

         let start_year = 2020;

         let current_date = new Date();
         let current_year = current_date.getFullYear();

         if (start_year !== current_year) this.year = start_year.toString() + ' - ' + current_year.toString();
     },
     computed: {
         ...mapGetters(["layoutConfig"]),

         /**
          * Check if footer container is fluid
          */
         widthFluid() {
             return this.layoutConfig("footer.width") === "fluid";
         }
     }
};
</script>
