<template>
    <ul class="menu-nav">
        <template v-for="item in menuItems">
            <router-link custom v-if="!item.is_section && !item.has_children" :to="item.route"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                ]">
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> {{ item.title }} </span>
                    </a>
                </li>
            </router-link>

            <li v-if="!item.is_section && item.has_children" aria-haspopup="true" data-menu-toggle="click"
                class="menu-item menu-item-submenu menu-item-open-dropdown"
                v-bind:class="{ 'menu-item-active': hasActiveChildren(item.route) }">
                <a href="#" class="menu-link menu-toggle">
                    <span class="menu-text"> {{ item.title }} </span>
                </a>
                <div class="menu-submenu menu-submenu-fixed">
                    <div class="menu-subnav megamenu" style="max-width: 1000px; overflow: scroll;">
                        <ul class="menu-content">
                            <li v-for="group in groups(item)" class="menu-item">
                                <h3 class="menu-heading menu-toggle">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text" v-if="group.title"> {{ group.title }} </span>
                                    <span class="menu-text" v-if="!group.title" v-html="'&nbsp;'"></span>
                                    <i class="menu-arrow"></i>
                                </h3>
                                <ul class="menu-inner">
                                    <router-link custom v-for="subitem in group.children" :key="subitem.route"
                                        :to="subitem.route" v-slot="{ href, navigate, isActive, isExactActive }">
                                        <li aria-haspopup="true" class="menu-item" :class="[
                                            isActive && 'menu-item-active',
                                            isExactActive && 'menu-item-active'
                                        ]">
                                            <a :href="href" class="menu-link" @click="navigate">
                                                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                                <span class="menu-text"> {{ subitem.title }} </span>
                                            </a>
                                        </li>
                                    </router-link>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "KTMenu",
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(["menuItems"]),
    },
    methods: {
        groups(item) {
            let groups = [];

            if (!item.is_section && item.has_children) {
                let i, j, tempArray, chunk = 6;
                let title = item.title;

                for (i = 0, j = item.children.length; i < j; i += chunk) {
                    tempArray = item.children.slice(i, i + chunk);

                    groups.push({ title: title, children: tempArray });

                    title = '';
                }
            }

            return groups;
        },

        hasActiveChildren(match) {
            return this.$route["path"].indexOf(match) !== -1;
        }
    }
};
</script>
