<template>
    <ul class="menu-nav">
        <template v-for="item in menuItems">
            <li v-if="item.is_section && item.access.includes($store.getters.currentUser.usertype)"
                class="menu-section">
                <h4 class="menu-text">{{ item.title }}</h4>
                <i :class="'menu-icon ' + item.icon"></i>
            </li>

            <router-link custom v-if="
                (
                    !item.is_section
                    &&
                    !item.has_children
                    &&
                    item.access.includes($store.getters.currentUser.usertype)
                )
                &&
                (
                    ($store.getters.currentUser.usertype !== 'Sodalizio')
                    ||
                    (
                        ($store.getters.currentUser.usertype === 'Sodalizio')
                        &&
                        (
                            (
                                (typeof item.has_single !== 'undefined')
                                &&
                                !item.has_single
                                &&
                                ($store.getters.currentUser.adminsportingclub.length > 1)
                            )
                            ||
                            (
                                (typeof item.has_single !== 'undefined')
                                &&
                                item.has_single
                                &&
                                ($store.getters.currentUser.adminsportingclub.length === 1)
                            )
                            ||
                            (typeof item.has_single === 'undefined')
                        )
                    )
                )
                &&
                (
                    ($store.getters.currentUser.usertype !== 'Sodalizio')
                    ||
                    (
                        ($store.getters.currentUser.usertype === 'Sodalizio')
                        &&
                        (
                            (
                                (typeof item.has_none_sportingclubs !== 'undefined')
                                &&
                                item.has_none_sportingclubs
                                &&
                                ($store.getters.currentUser.adminsportingclub.length === 0)
                            )
                            ||
                            (
                                (typeof item.has_none_sportingclubs !== 'undefined')
                                &&
                                !item.has_none_sportingclubs
                                &&
                                ($store.getters.currentUser.adminsportingclub.length > 0)
                            )
                            ||
                            (typeof item.has_none_sportingclubs === 'undefined')
                        )
                    )
                )
                &&
                (
                    ($store.getters.currentUser.usertype !== 'Sodalizio')
                    ||
                    (
                        ($store.getters.currentUser.usertype === 'Sodalizio')
                        &&
                        (
                            (
                                (typeof item.has_some_sportingclubs !== 'undefined')
                                &&
                                item.has_some_sportingclubs
                                &&
                                ($store.getters.currentUser.adminsportingclub.length >= 1)
                            )
                            ||
                            (typeof item.has_some_sportingclubs === 'undefined')
                        )
                    )
                )
            " :to="(($store.getters.currentUser.usertype === 'Comitato') ? item.route.replace('[ID_COMITATO]', $store.getters.currentUser.admincommittee) : (($store.getters.currentUser.usertype === 'Sodalizio' && typeof item.has_single !== 'undefined' && item.has_single && $store.getters.currentUser.adminsportingclub.length === 1) ? item.route.replace('[ID_SODALIZIO]', $store.getters.currentUser.adminsportingclub[0]) : item.route))"
                v-slot="{ href, navigate, isActive, isExactActive }">
                <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                ]">
                    <a :href="(($store.getters.currentUser.usertype === 'Comitato') ? href.replace('[ID_COMITATO]', $store.getters.currentUser.admincommittee) : (($store.getters.currentUser.usertype === 'Sodalizio' && typeof item.has_single !== 'undefined' && item.has_single && $store.getters.currentUser.adminsportingclub.length === 1) ? href.replace('[ID_SODALIZIO]', $store.getters.currentUser.adminsportingclub[0]) : href))"
                        class="menu-link" @click="navigate">
                        <i :class="'menu-icon ' + item.icon"></i>
                        <span class="menu-text">{{ item.title }}</span>
                    </a>
                </li>
            </router-link>

            <li v-if="
                (!item.is_section && item.has_children && item.access.includes($store.getters.currentUser.usertype))
                &&
                (
                    (
                        $store.getters.currentUser.usertype !== 'Sodalizio'
                    )
                    ||
                    (
                        $store.getters.currentUser.usertype === 'Sodalizio'
                    )
                    &&
                    (
                        (
                            typeof item.has_none_sportingclubs !== 'undefined'
                            &&
                            item.has_none_sportingclubs
                            &&
                            $store.getters.currentUser.adminsportingclub.length === 0
                        )
                        ||
                        (
                            typeof item.has_none_sportingclubs !== 'undefined'
                            &&
                            !item.has_none_sportingclubs
                            &&
                            $store.getters.currentUser.adminsportingclub.length > 0
                        )
                        ||
                        (
                            typeof item.has_none_sportingclubs === 'undefined'
                        )
                    )
                )
            " aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
                v-bind:class="{ 'menu-item-open': hasActiveChildren(item.route) }">
                <a href="#" class="menu-link menu-toggle">
                    <i :class="'menu-icon ' + item.icon"></i>
                    <span class="menu-text">{{ item.title }}</span>
                    <i class="menu-arrow"></i>
                </a>
                <div class="menu-submenu">
                    <span class="menu-arrow"></span>
                    <ul class="menu-subnav">
                        <li aria-haspopup="true" class="menu-item menu-item-parent">
                            <span class="menu-link">
                                <span class="menu-text">{{ item.title }}</span>
                            </span>
                        </li>

                        <router-link custom v-for="subitem in item.children" :key="subitem.route" :to="subitem.route"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                            v-if="subitem.access.includes($store.getters.currentUser.usertype)">
                            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
                                isActive && 'menu-item-active',
                                isExactActive && 'menu-item-active'
                            ]">
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot">
                                        <span></span>
                                    </i>
                                    <span class="menu-text">{{ subitem.title }}</span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "KTMenu",
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(["menuItems"]),
    },
    methods: {
        hasActiveChildren(match) {
            return this.$route["path"].indexOf(match) !== -1;
        }
    }
};
</script>
