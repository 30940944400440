var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.menuItems),function(item){return [(!item.is_section && !item.has_children)?_c('router-link',{attrs:{"custom":"","to":item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(item.title)+" ")])])])]}}],null,true)}):_vm._e(),(!item.is_section && item.has_children)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(item.route) },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"1000px","overflow":"scroll"}},[_c('ul',{staticClass:"menu-content"},_vm._l((_vm.groups(item)),function(group){return _c('li',{staticClass:"menu-item"},[_c('h3',{staticClass:"menu-heading menu-toggle"},[_vm._m(0,true),(group.title)?_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(group.title)+" ")]):_vm._e(),(!group.title)?_c('span',{staticClass:"menu-text",domProps:{"innerHTML":_vm._s('&nbsp;')}}):_vm._e(),_c('i',{staticClass:"menu-arrow"})]),_c('ul',{staticClass:"menu-inner"},_vm._l((group.children),function(subitem){return _c('router-link',{key:subitem.route,attrs:{"custom":"","to":subitem.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                                        isActive && 'menu-item-active',
                                        isExactActive && 'menu-item-active'
                                    ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(subitem.title)+" ")])])])]}}],null,true)})}),1)])}),0)])])]):_vm._e()]})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])}]

export { render, staticRenderFns }