<template>
    <div class="topbar-item">
        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                Ciao,
            </span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {{ $store.getters.currentUser.name }} {{ $store.getters.currentUser.surname }}
            </span>
            <span class="symbol symbol-35 symbol-light-success">
                <img v-if="false" alt="Pic" :src="picture" />
                <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
                    {{ $store.getters.currentUser.name.substring(0, 1) }}
                </span>
            </span>
        </div>

        <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
            <!--begin::Header-->
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 class="font-weight-bold m-0">
                    {{ $store.getters.currentUser.usertype }}
                </h3>
                <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i class="ki ki-close icon-xs text-muted"></i>
                </a>
            </div>
            <!--end::Header-->

            <!--begin::Content-->
            <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;">
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                    <div v-if="false" class="symbol symbol-100 mr-5">
                        <img class="symbol-label" :src="picture" alt="" />
                        <i class="symbol-badge bg-success"></i>
                    </div>
                    <div class="d-flex flex-column">
                        <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                            {{ $store.getters.currentUser.first_name }} {{ $store.getters.currentUser.last_name }}
                        </a>
                        <div class="navi mt-2">
                            <a href="#" class="navi-item">
                                <span class="navi-link p-0 pb-2">
                                    <span class="navi-icon mr-1">
                                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                    <span class="navi-text text-muted text-hover-primary">
                                        {{ $store.getters.currentUser.email }}
                                    </span>
                                </span>
                            </a>
                        </div>
                        <button class="btn btn-light-primary btn-bold" @click="onLogout">
                            Esci
                        </button>
                    </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <!--begin::Nav-->
                <div v-if="false" class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <router-link custom to="/" @click.native="closeOffcanvas" href="#" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-success">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/General/Notification2.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">Link 1</div>
                                <div class="text-muted">
                                    Testo 1
                                    <span class="label label-light-danger label-inline font-weight-bold">
                                        badge 1
                                    </span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <!--end:Item-->
                    <!--begin::Item-->
                    <router-link custom to="/" @click.native="closeOffcanvas" href="#" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-warning">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">Link 2</div>
                                <div class="text-muted">Testo 2</div>
                            </div>
                        </div>
                    </router-link>
                    <!--end:Item-->
                    <!--begin::Item-->
                    <router-link custom to="/" @click.native="closeOffcanvas" href="#" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-danger">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">Link 3</div>
                                <div class="text-muted">Testo 3</div>
                            </div>
                        </div>
                    </router-link>
                    <!--end:Item-->
                    <!--begin::Item-->
                    <router-link custom to="/" @click.native="closeOffcanvas" href="#" class="navi-item">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Communication/Mail-opened.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">Link 4</div>
                                <div class="text-muted">Testo 4</div>
                            </div>
                        </div>
                    </router-link>
                    <!--end:Item-->
                </div>
                <!--end::Nav-->
                <div v-if="false" class="separator separator-dashed my-7"></div>
                <!--begin::Notifications-->
                <div v-if="false">
                    <!--begin:Heading-->
                    <h5 class="mb-5">Ultime notifiche</h5>
                    <!--end:Heading-->
                    <template v-for="(item, i) in list">
                        <!--begin::Item -->
                        <div class="d-flex align-items-center rounded p-5 gutter-b"
                            v-bind:class="`bg-light-${item.type}`" v-bind:key="i">
                            <span class="svg-icon mr-5" v-bind:class="`svg-icon-${item.type}`">
                                <span class="svg-icon svg-icon-lg">
                                    <!--begin::Svg Icon-->
                                    <inline-svg :src="item.svg" />
                                    <!--end::Svg Icon-->
                                </span>
                            </span>
                            <div class="d-flex flex-column flex-grow-1 mr-2">
                                <a href="#"
                                    class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
                                    {{ item.title }}
                                </a>
                                <span class="text-muted font-size-sm">
                                    {{ item.desc }}
                                </span>
                            </div>
                            <span class="font-weight-bolder py-1 font-size-lg" v-bind:class="`text-${item.type}`">
                                {{ item.alt }}
                            </span>
                        </div>
                        <!--end::Item -->
                    </template>
                </div>
                <!--end::Notifications-->
            </perfect-scrollbar>
            <!--end::Content-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
    overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
    name: "KTQuickUser",
    data() {
        return {
            list: [
                {
                    title: "Titolo 1",
                    desc: "Descrizione 1",
                    alt: "testo 1",
                    svg: "media/svg/icons/Home/Library.svg",
                    type: "warning"
                },
                {
                    title: "Titolo 2",
                    desc: "Descrizione 2",
                    alt: "testo 2",
                    svg: "media/svg/icons/Communication/Write.svg",
                    type: "success"
                },
                {
                    title: "Titolo 3",
                    desc: "Descrizione 3",
                    alt: "testo 3",
                    svg: "media/svg/icons/Communication/Group-chat.svg",
                    type: "danger"
                },
                {
                    title: "Titolo 4",
                    desc: "Descrizione 4",
                    alt: "testo 4",
                    svg: "media/svg/icons/General/Attachment2.svg",
                    type: "info"
                }
            ]
        };
    },
    mounted() {
        // Init Quick User Panel
        KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    },
    methods: {
        onLogout() {
            this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
        },
        closeOffcanvas() {
            new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
        }
    },
    computed: {
        picture() {
            return process.env.BASE_URL + "media/users/300_21.jpg";
        }
    }
};
</script>
