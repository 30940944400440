<template>
    <div class="topbar-item">
        <div class="btn btn-icon btn-clean btn-lg mx-2" id="kt_quick_cart_toggle">
            <span class="svg-icon svg-icon-xl svg-icon-primary d-block" style="position: absolute;">
                <i class="fas fa-shopping-cart text-primary"
                    style="position: absolute; top: -17px; left: -25px; font-size: 2.3rem;"></i>
                <b-badge pill variant="danger" style="position: absolute; top: -20px; left: 10px;">{{ totalRequests
                    }}</b-badge>
            </span>
        </div>

        <b-modal ref="pagamentoRichiestaModal" size="lg" hide-footer title="Pagamento Richieste">
            <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                <div class="table-responsive">
                    <table
                        class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                        <template v-for="request in currentRequestes.requestes">
                            <thead>
                                <tr class="text-left">
                                    <th class="bg-primary" colspan="4"><span
                                            class="text-white text-hover-white">Richiesta #{{ request.id }} del
                                            {{ request.date }}</span></th>
                                </tr>
                                <tr class="text-left">
                                    <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span>
                                    </th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO
                                            UNITARIO</span></th>
                                    <th class="bg-primary text-right"><span
                                            class="text-white text-hover-white">QUANTITÀ</span></th>
                                    <th class="bg-primary text-right"><span
                                            class="text-white text-hover-white">IMPORTO</span></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="product in request.products">
                                    <td>{{ product.label }}</td>
                                    <td class="text-right">{{ formatEuro(product.price) }}</td>
                                    <td class="text-right">{{ product.quantity }}</td>
                                    <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                </tr>

                                <tr>
                                    <th colspan="3" class="text-right bg-primary text-white">TOTALE RICHIESTA
                                        #{{ request.id }} del {{ request.date }}</th>
                                    <th class="text-right text-primary font-size-h3">{{ formatEuro(request.total) }}
                                    </th>
                                </tr>
                            </tbody>

                        </template>

                        <tfoot>
                            <tr>
                                <th colspan="3" class="text-right bg-success text-white">TOTALE RICHIESTE DA PAGARE</th>
                                <th v-if="currentRequestes.type === 'Sodalizio'"
                                    class="text-right text-success font-size-h3">{{
                                        formatEuro(sportingclubGrandtotal) }}</th>
                                <th v-if="currentRequestes.type === 'Comitato'"
                                    class="text-right text-success font-size-h3">{{
                                        formatEuro(committeeGrandtotal) }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div v-if="currentRequestes.type === 'Sodalizio'">
                    <div class="form-group">
                        <label>Modalità di pagamento</label>
                        <b-form-radio-group v-model="modalitadipagamento" :options="[
                            { text: 'Contanti', value: 'Contanti' },
                            { text: 'Bonifico', value: 'Bonifico' },
                        ]">
                        </b-form-radio-group>
                    </div>
                </div>

                <div v-if="currentRequestes.type === 'Sodalizio' && modalitadipagamento === 'Bonifico'"
                    class="form-group">
                    <FileUpload id="filebonifico" name="filebonifico" :multiple="false" v-model="filebonifico">
                    </FileUpload>

                    <div v-for="(error, index) in fileErrors.filebonifico" class="invalid-feedback d-block">{{ error }}
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <b-button class="mt-3 font-weight-bolder" variant="danger"
                        @click.prevent="hidePagamentoRichiestaModal">Annulla</b-button>
                    <b-button class="ml-3 mt-3 font-weight-bolder" variant="success"
                        @click.prevent="confermaPagamentoRichiesta"><i class="fas fa-cash-register"></i> Conferma
                        Pagamento Richieste</b-button>
                </div>
            </b-overlay>
        </b-modal>

        <div id="kt_quick_cart" ref="kt_quick_cart" class="offcanvas offcanvas-right">
            <!--begin::Header-->
            <div class="offcanvas-header">
                <div class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                    :style="{ backgroundImage: `url(${backgroundImage})` }">
                    <div>
                        <h4 class="d-flex flex-center rounded-top">
                            <span class="text-white">Carrello</span>
                            <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                                {{ totalRequests }}
                            </span>
                        </h4>

                        <div class="d-flex justify-content-end pr-5" style="margin-top: -35px;">
                            <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary"
                                id="kt_quick_cart_close">
                                <i class="ki ki-close icon-xs text-muted"></i>
                            </a>
                        </div>
                    </div>

                    <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8 justify-content-center"
                        role="tablist">
                        <li class="nav-item">
                            <a v-on:click="setActiveTab" data-tab="0" class="nav-link active" data-toggle="tab" href="#"
                                role="tab">
                                Sodalizio
                                <b-badge v-if="sportingclubRequests.length" pill variant="danger">{{
                                    sportingclubRequests.length
                                }}</b-badge>
                            </a>
                        </li>
                        <li v-if="$store.getters.currentUser.usertype !== 'Sodalizio'" class="nav-item">
                            <a v-on:click="setActiveTab" data-tab="1" class="nav-link" data-toggle="tab" href="#"
                                role="tab">
                                Comitato
                                <b-badge v-if="committeeRequests.length" pill variant="danger">{{
                                    committeeRequests.length
                                }}</b-badge>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!--end::Header-->

            <div class="offcanvas-content">
                <b-tabs class="hide-tabs" v-model="tabIndex">
                    <b-tab active class="p-8 pt-0">
                        <perfect-scrollbar class="offcanvas-wrapper scroll">
                            <template v-if="sportingclubRequests.length" v-for="request in sportingclubRequests">
                                <!--begin::Item-->
                                <div v-if="request.state === 'Carrello'"
                                    class="d-flex align-items-center justify-content-between py-8">
                                    <div class="d-flex flex-column mr-2">
                                        <a href="#"
                                            class="font-weight-bold text-dark-75 font-size-lg text-hover-primary">{{
                                                request.date }}</a>

                                        <template v-for="(product, index) in request.products">
                                            <div class="text-muted mt-2">{{ product.label }}</div>
                                            <div class="d-flex align-items-center mt-2">
                                                <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">{{
                                                    formatEuro(product.price) }}</span>
                                                <span class="text-muted mr-1">per</span>
                                                <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">{{
                                                    product.quantity }}</span>
                                                <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"
                                                    @click.prevent="sportingclubDecrement(request, product)">
                                                    <i class="ki ki-minus icon-xs"></i>
                                                </a>
                                                <a href="#" class="btn btn-xs btn-light-success btn-icon"
                                                    @click.prevent="sportingclubIncrement(request, product)">
                                                    <i class="ki ki-plus icon-xs"></i>
                                                </a>
                                                <span class="font-weight-bold ml-1 mr-1 text-dark-75 font-size-lg">{{
                                                    formatEuro(product.subtotal) }}</span>
                                            </div>
                                        </template>

                                        <div class="d-flex justify-content-between align-items-center mt-2">
                                            <a href="#" class="btn btn-icon btn-sm btn-danger"
                                                @click.prevent="sportingclubDraftRequest(request.id)">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                            <div class="font-weight-bold">TOTALE {{ formatEuro(request.total) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Item-->
                                <!--begin::Separator-->
                                <div class="separator separator-solid"></div>
                                <!--end::Separator-->
                            </template>

                            <div v-if="!sportingclubRequests.length">Il tuo carrello è vuoto.</div>
                        </perfect-scrollbar>
                    </b-tab>

                    <b-tab class="p-8 pt-0" v-if="$store.getters.currentUser.usertype !== 'Sodalizio'">
                        <perfect-scrollbar class="offcanvas-wrapper scroll">
                            <template v-if="committeeRequests.length" v-for="request in committeeRequests">
                                <!--begin::Item-->
                                <div v-if="request.state === 'Carrello'"
                                    class="d-flex align-items-center justify-content-between py-8">
                                    <div class="d-flex flex-column mr-2">
                                        <a href="#"
                                            class="font-weight-bold text-dark-75 font-size-lg text-hover-primary">{{
                                                request.date }}</a>

                                        <template v-for="(product, index) in request.products">
                                            <div class="text-muted mt-2">{{ product.label }}</div>
                                            <div class="d-flex align-items-center mt-2">
                                                <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">{{
                                                    formatEuro(product.price) }}</span>
                                                <span class="text-muted mr-1">per</span>
                                                <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">{{
                                                    product.quantity }}</span>
                                                <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"
                                                    @click.prevent="committeeDecrement(request, product)">
                                                    <i class="ki ki-minus icon-xs"></i>
                                                </a>
                                                <a href="#" class="btn btn-xs btn-light-success btn-icon"
                                                    @click.prevent="committeeIncrement(request, product)">
                                                    <i class="ki ki-plus icon-xs"></i>
                                                </a>
                                                <span class="font-weight-bold ml-1 mr-1 text-dark-75 font-size-lg">{{
                                                    formatEuro(product.subtotal) }}</span>
                                            </div>
                                        </template>

                                        <div class="d-flex justify-content-between align-items-center mt-2">
                                            <a href="#" class="btn btn-icon btn-sm btn-danger"
                                                @click.prevent="committeeDraftRequest(request)">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                            <div class="font-weight-bold">TOTALE {{ formatEuro(request.total) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Item-->
                                <!--begin::Separator-->
                                <div class="separator separator-solid"></div>
                                <!--end::Separator-->
                            </template>

                            <div v-if="!committeeRequests.length">Il tuo carrello è vuoto.</div>
                        </perfect-scrollbar>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="offcanvas-footer">
                <div class="d-flex align-items-center justify-content-between mb-1 px-8">
                    <span class="font-weight-bold text-muted font-size-sm mr-2">{{ (totalProducts ? 'Totale' : '&nbsp;')
                        }}</span>
                    <span v-if="tabIndex === 0" class="font-weight-bolder text-primary text-right">{{ (totalProducts ?
                        formatEuro(sportingclubGrandtotal) : '&nbsp;') }}</span>
                    <span v-if="tabIndex === 1" class="font-weight-bolder text-primary text-right">{{ (totalProducts ?
                        formatEuro(committeeGrandtotal) : '&nbsp;') }}</span>
                </div>
                <div class="text-right px-8">
                    <button v-if="totalProducts" @click.prevent="showPagamentoRichiestaModal" type="button"
                        class="btn btn-primary btn-sm text-weight-bold">Vai alla Cassa</button>
                </div>
            </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
#kt_quick_cart {
    overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickCartPanel from "@/assets/js/layout/extended/quick-cart.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApiService from "@/core/services/api.service";
import FileUpload from "@/view/components/FileUpload.vue";
import Swal from 'sweetalert2';

export default {
    name: "KTQuickCart",

    components: {
        FileUpload,
    },

    data() {
        return {
            tabIndex: 0,

            loading: false,

            sportingclubRequests: [],
            sportingclubid: null,

            committeeRequests: [],
            committeeid: null,

            sportingclubGrandtotal: 0,
            committeeGrandtotal: 0,

            modalitadipagamento: 'Contanti',
            filebonifico: '',
            fileErrors: [],
        };
    },

    created() {
        let vm = this;
        setInterval(function () {
            vm.sportingclubid = localStorage.getItem('currentSportingClubId');
            vm.committeeid = localStorage.getItem('currentCommitteeId');
            if (!KTLayoutQuickCartPanel.getOffCanvasObject().isShown() && vm.$store.getters.currentUser.usertype) {
                if (vm.sportingclubid) {
                    vm.loadSportingclubRequestes();
                }

                if (vm.committeeid && vm.$store.getters.currentUser.usertype !== 'Sodalizio') {
                    vm.loadCommitteeRequestes();
                }
            }
        }, 2000);
    },

    mounted() {
        KTLayoutQuickCartPanel.init(this.$refs["kt_quick_cart"]);
    },

    watch: {
    },

    methods: {
        formatEuro(number) {
            return (new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(number));
        },

        sportingclubIncrement(request, product) {
            if (typeof this.sportingclubid !== "undefined") {
                let url = '';

                url = '/requestes/cart-increment?idsportingclub=' + this.sportingclubid;

                let data = {
                    request: request,
                    product: product,
                };

                ApiService.post(url, data)
                    .then((response) => {
                        this.sportingclubRequests = [];
                        for (let i in response.data.requests) {
                            if (response.data.requests[i].state === 'Carrello') {
                                this.sportingclubRequests.push(JSON.parse(JSON.stringify(response.data.requests[i])));
                            }
                        }

                        this.sportingclubGrandtotal = 0;
                        for (let i in this.sportingclubRequests) {
                            this.sportingclubGrandtotal += this.sportingclubRequests[i].total;
                        }
                    })
                    .catch((error) => {
                    })
            }
        },

        committeeIncrement(request, product) {
            if (typeof this.committeeid !== "undefined") {
                let url = '';

                url = '/requestes-committee/cart-increment?idcommittee=' + this.committeeid;

                let data = {
                    request: request,
                    product: product,
                };

                ApiService.post(url, data)
                    .then((response) => {
                        this.committeeRequests = [];
                        for (let i in response.data.requests) {
                            if (response.data.requests[i].state === 'Carrello') {
                                this.committeeRequests.push(JSON.parse(JSON.stringify(response.data.requests[i])));
                            }
                        }

                        this.committeeGrandtotal = 0;
                        for (let i in this.committeeRequests) {
                            this.committeeGrandtotal += this.committeeRequests[i].total;
                        }
                    })
                    .catch((error) => {
                    })
            }
        },

        sportingclubDecrement(request, product) {
            if (product.quantity >= 1) {
                if (typeof this.sportingclubid !== "undefined") {
                    let url = '';

                    url = '/requestes/cart-decrement?idsportingclub=' + this.sportingclubid;

                    let data = {
                        request: request,
                        product: product,
                    };

                    ApiService.post(url, data)
                        .then((response) => {
                            this.sportingclubRequests = [];
                            for (let i in response.data.requests) {
                                if (response.data.requests[i].state === 'Carrello') {
                                    this.sportingclubRequests.push(JSON.parse(JSON.stringify(response.data.requests[i])));
                                }
                            }

                            this.sportingclubGrandtotal = 0;
                            for (let i in this.sportingclubRequests) {
                                this.sportingclubGrandtotal += this.sportingclubRequests[i].total;
                            }
                        })
                        .catch((error) => {
                        })
                }
            }
        },

        committeeDecrement(request, product) {
            if (product.quantity >= 1) {
                if (typeof this.committeeid !== "undefined") {
                    let url = '';

                    url = '/requestes-committee/cart-decrement?idcommittee=' + this.committeeid;

                    let data = {
                        request: request,
                        product: product,
                    };

                    ApiService.post(url, data)
                        .then((response) => {
                            this.committeeRequests = [];
                            for (let i in response.data.requests) {
                                if (response.data.requests[i].state === 'Carrello') {
                                    this.committeeRequests.push(JSON.parse(JSON.stringify(response.data.requests[i])));
                                }
                            }

                            this.committeeGrandtotal = 0;
                            for (let i in this.committeeRequests) {
                                this.committeeGrandtotal += this.committeeRequests[i].total;
                            }
                        })
                        .catch((error) => {
                        })
                }
            }
        },

        loadSportingclubRequestes() {
            if (typeof this.sportingclubid !== "undefined") {
                let url = '';

                url = '/requestes/load-requestes?idsportingclub=' + this.sportingclubid + '&state=Carrello';
                ApiService.query(url)
                    .then((response) => {
                        this.sportingclubRequests = [];
                        for (let i in response.data.requests) {
                            if (response.data.requests[i].state === 'Carrello') {
                                this.sportingclubRequests.push(JSON.parse(JSON.stringify(response.data.requests[i])));
                            }
                        }

                        this.sportingclubGrandtotal = 0;
                        for (let i in this.sportingclubRequests) {
                            this.sportingclubGrandtotal += this.sportingclubRequests[i].total;
                        }
                    })
                    .catch((error) => {
                    })
            }
        },

        loadCommitteeRequestes() {
            if (typeof this.committeeid !== "undefined") {
                let url = '';

                url = '/requestes-committee/load-requestes?idcommittee=' + this.committeeid + '&state=Carrello';
                ApiService.query(url)
                    .then((response) => {
                        this.committeeRequests = [];
                        for (let i in response.data.requests) {
                            if (response.data.requests[i].state === 'Carrello') {
                                this.committeeRequests.push(JSON.parse(JSON.stringify(response.data.requests[i])));
                            }
                        }

                        this.committeeGrandtotal = 0;
                        for (let i in this.committeeRequests) {
                            this.committeeGrandtotal += this.committeeRequests[i].total;
                        }
                    })
                    .catch((error) => {
                    })
            }
        },

        sportingclubDraftRequest(request_id) {
            Swal.fire({
                title: 'Riporta richiesta in bozza',
                text: "Sei sicuro di voler riportare la richiesta in bozza?",
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = '';

                    url = '/requestes/draft-request-from-cart';

                    ApiService.post(url, { request_id: request_id, idsportingclub: this.sportingclubid })
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Richiesta riportata correttamente in bozza.', {
                                    title: 'Richiesta riportata correttamente in bozza',
                                    variant: 'success',
                                    solid: true
                                });

                                this.loadSportingclubRequestes();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si è verificato un errore nello spostamento della richiesta.', {
                                    title: 'Errore nello spostamento della richiesta',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch((error) => {
                            this.$bvToast.toast('Si è verificato un errore nello spostamento della richiesta.', {
                                title: 'Errore nello spostamento della richiesta',
                                variant: 'danger',
                                solid: true
                            });
                        })
                }
            });
        },

        committeeDraftRequest(request) {
            Swal.fire({
                title: 'Riporta richiesta in bozza',
                text: "Sei sicuro di voler riportare la richiesta in bozza?",
                icon: 'error',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = '';

                    url = '/requestes-committee/draft-request-from-cart';

                    ApiService.post(url, { request_id: request.id, idcommittee: this.committeeid, idannuality: request.idannuality })
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Richiesta riportata correttamente in bozza.', {
                                    title: 'Richiesta riportata correttamente in bozza',
                                    variant: 'success',
                                    solid: true
                                });

                                this.loadCommitteeRequestes();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si è verificato un errore nello spostamento della richiesta.', {
                                    title: 'Errore nello spostamento della richiesta',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch((error) => {
                            this.$bvToast.toast('Si è verificato un errore nello spostamento della richiesta.', {
                                title: 'Errore nello spostamento della richiesta',
                                variant: 'danger',
                                solid: true
                            });
                        })
                }
            });
        },

        showPagamentoRichiestaModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs['pagamentoRichiestaModal'].show();
            }, 200);
        },

        hidePagamentoRichiestaModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs['pagamentoRichiestaModal'].hide();
            }, 200);
        },

        confermaPagamentoRichiesta() {
            Swal.fire({
                title: 'Conferma pagamento richieste',
                text: "Sei sicuro di confermare il pagamento delle richieste?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '';
                    let data = {};

                    if (this.currentRequestes.type === 'Sodalizio') {
                        url = '/requestes/payment';

                        data = { idsportingclub: this.sportingclubid, modalitadipagamento: this.modalitadipagamento, filebonifico: this.filebonifico };
                    }

                    if (this.currentRequestes.type === 'Comitato') {
                        url = '/requestes-committee/payment';

                        data = { idcommittee: this.committeeid };
                    }

                    this.fileErrors = {};

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Richieste messe in stato di pagamento', {
                                    title: 'Richiesta messe correttamente in stato di pagamento',
                                    variant: 'success',
                                    solid: true
                                });

                                this.hidePagamentoRichiestaModal();

                                if (this.currentRequestes.type === 'Sodalizio') {
                                    this.loadSportingclubRequestes();
                                }

                                if (this.currentRequestes.type === 'Comitato') {
                                    this.loadCommitteeRequestes();
                                }

                                this.loading = false;
                            }

                            if (response.data.status === 'KO') {
                                if (typeof response.data.message !== "undefined") {
                                    this.$bvToast.toast('Si è verificato un errore nella conferma del pagamento delle richieste: ' + response.data.message, {
                                        title: 'Errore nella conferma del pagamento delle richieste',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                                else {
                                    this.$bvToast.toast('Si è verificato un errore nella conferma del pagamento delle richieste', {
                                        title: 'Errore nella conferma del pagamento delle richieste',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }

                                if (typeof response.data.fileerrors !== "undefined") {
                                    this.fileErrors = response.data.fileerrors;
                                }

                                this.loading = false;
                            }
                        })
                        .catch(({ response }) => {
                            if (response.status === 422) {
                                this.$bvToast.toast('Si è verificato un errore nella conferma del pagamento delle richieste', {
                                    title: 'Errore nella conferma del pagamento delle richieste',
                                    variant: 'danger',
                                    solid: true
                                });

                                if (typeof response.data.fileerrors !== "undefined") {
                                    this.fileErrors = response.data.fileerrors;
                                }
                            }
                            else {
                                console.log(response);
                            }

                            this.loading = false;
                        })
                }
            });
        },

        setActiveTab(event) {
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

            // set current active tab
            event.target.classList.add("active");
        }
    },
    computed: {
        currentRequestes() {
            let currentRequestes = {};

            if (this.tabIndex === 0) {
                currentRequestes = {
                    requestes: this.sportingclubRequests,
                    type: 'Sodalizio',
                };
            }

            if (this.tabIndex === 1) {
                currentRequestes = {
                    requestes: this.committeeRequests,
                    type: 'Comitato',
                };
            }

            return currentRequestes;
        },

        totalProducts() {
            let currentRequestes = {};

            if (this.tabIndex === 0) {
                currentRequestes = {
                    requestes: this.sportingclubRequests,
                    type: 'Sodalizio',
                };
            }

            if (this.tabIndex === 1) {
                currentRequestes = {
                    requestes: this.committeeRequests,
                    type: 'Comitato',
                };
            }

            let totalProducts = 0;
            for (let i in currentRequestes.requestes) {
                totalProducts += currentRequestes.requestes[i].products.length;
            }

            return totalProducts;
        },

        totalRequests() {
            let totalRequests = 0;

            totalRequests += this.sportingclubRequests.length;

            if (this.$store.getters.currentUser.usertype !== 'Sodalizio') {
                totalRequests += this.committeeRequests.length;
            }

            return totalRequests;
        },

        backgroundImage() {
            return process.env.BASE_URL + "media/misc/bg-1.jpg";
        }
    },
};
</script>
